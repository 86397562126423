import React from "react";
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import '../scss/article.scss';

export default function ArticleTemplate ({ data, pageContext, path }) {
  const article = data.prismicArticle.data;

  return (
    <Layout path={path}>
      <Helmet>
        <title>{article.title.text}</title>
        <meta name="description" content={article.meta_description?.text} />
        <meta name="og:title" content={article.title.text} />
        <meta name="og:description" content={article.meta_description?.text} />
        <meta name="og:image" content={article.hero_background?.fixed?.src} />
      </Helmet>

      <main className={`container container-article`}>

        <section className={`article-hero`}>
          <GatsbyImage
            className='hero-img'
            layout="fullWidth"
            image={getImage(article.hero_background)}
            alt={article.hero_background.alt ?? ''}
            placeholder="blurred"
            loading="eager"
          />
          <div className={`hero-content `}>
            <div className={`category`}>{article.category}</div>
            <RichText render={article.title.raw} />
            <div>By: {article.author}</div>
          </div>
        </section>

        <section className={`article-content`}>
          <SliceZone sliceZone={article.body}/>
        </section>

        <section className={`article-content article-pagination`}>
          {pageContext.previous &&
            <div>
              <Link to={pageContext.previous?.url} rel="previous">Previous
                <div className={`subhead01`}>{pageContext.previous?.data?.title?.text}</div>
              </Link>
            </div>
          }
          {pageContext.next &&
            <div>
              <Link to={pageContext.next?.url} rel="next">Next
                <div className={`subhead01`}>{pageContext.next?.data?.title?.text}</div>
              </Link>
            </div>
          }
        </section>

      </main>
    </Layout>
  );
}

export const query = graphql`
query ArticleQuery ($id: String!) {
  prismicArticle(id: {eq: $id}) {
    _previewable

    data {
      title { text, raw }
      meta_description { text }

      author, category, publish_date, read_time

      hero_background {
        alt, gatsbyImageData
        fixed(imgixParams: {width: 600}, width: 600) { src }
      }

      body {
        ... on PrismicArticleDataBodyArticleContent {
          ...ArticleDataBodyArticleContent
        }
        ... on PrismicArticleDataBodyEmbed {
          ...ArticleDataBodyEmbed
        }
      }
    }
  }
}
`;
